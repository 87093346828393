/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import dataJSON from '../data/data.js'
import '../pages/style.css'
import {
  CalendarIcon,
  HomeIcon,
  MapIcon,
  MenuIcon,
  ArchiveIcon,
  SpeakerphoneIcon,
  UserGroupIcon,
  XIcon,
} from '@heroicons/react/outline'

import iconInstagran from '../assets/svg/icon-instagram.svg'
import iconTikTok from '../assets/svg/icon-tiktok.svg'
import iconWhatsApp from '../assets/svg/icon-whats.svg'
import iconYoutube from '../assets/svg/icon-youtube.svg'
import iconMail from '../assets/svg/icon-mail.svg'
import iconFacebook from '../assets/svg/icon-facebook.svg'

const isBrowser = typeof window !== "undefined"


const navigation = dataJSON.app_pages.filter(item=>item.menu_name && (item.menu_show.filter(item2=>item2=='desktop').length || item.menu_show.filter(item2=>item2=='mobile').length))

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


function logout(){
  if (!isBrowser) return
  window.AngularEcommerce.web.stores.dispatch('auth/logout')
  .then(()=>{
    setTimeout(()=>{
      window.location.href = "/"
    },1000)
  })
}

function myaccount(){
  if (!isBrowser) return
  window.location.href = "/my-account"
    
}



const MenuSidebar = (props)=> {
  const [user, setUser] = useState({})
  const [sidebarOpen, setSidebarOpen] = useState(props.sidebarOpen)

  function login(event){

    //event.preventDefault();
    
    setTimeout(()=>{
      props.menuChangeStatus()
      document.querySelector('ae-login-modal').show = true
    })
  }
  
  function checkout(event){
  
    //event.preventDefault();
    
    setTimeout(()=>{
      props.menuChangeStatus()
      document.querySelector('ae-checkout-modal').open = true
    })
  }
  
  function signup(event){
  
    //event.preventDefault();
    
    setTimeout(()=>{
      props.menuChangeStatus()
      document.querySelector('ae-signup-modal').show = true
    })
  }

  function userCurrentReady(){
    if (!isBrowser) return
    window.addEventListener('userCurrentReady', ()=>{
      setTimeout(()=>{
        setUser(window.AngularEcommerce.web.stores.state.auth.user)
      })
    })
  }

  userCurrentReady()
  

  return (
    <>
      <div className="h-full flex">
        <Transition.Root show={props.sidebarOpen} as={Fragment}>
          <Dialog as="div" className="fixed inset-0 flex z-40 " onClose={props.menuChangeStatus}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative flex-1 flex flex-col max-w-lg w-full focus:outline-none" style={{backgroundColor:'var(--primary)'}}>
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-2 right-0 mr-4 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => props.menuChangeStatus()}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                  <div className="flex px-3">
                    <div className='w-full'>
                      <div className="flex gap-3 border-b border-gray-600 py-4 w-full" style={{display:user?.id?'flex':'none'}}>
                        <img 
                          className={`rounded-full w-20 h-20 `}
                          style={{border:'1px solid var(--secondary)'}} 
                          src="https://militaryhealthinstitute.org/wp-content/uploads/sites/37/2021/08/blank-profile-picture-png.png" 
                        />
                        <div style={{display:user?.id?'flex':'none'}} className="flex flex-col gap-5 justify-center overflow-hidden">
                          <span className="text-white text-lg whitespace-nowrap">{user?.first_name} {user?.last_name}</span>
                          <span className="text-white text-sm whitespace-nowrap" style={{marginTop:'-20px'}}>{user?.email}</span>
                          <div className="flex flex-col sm:flex-row gap-5 w-full" style={{marginTop:'-10px'}}>
                            <button onClick={()=>myaccount()} className="px-4 py-1 rounded hover:opacity-90 font-semibold w-full sm:w-40" style={{backgroundColor:'var(--secondary)', color:'var(--primary)'}}>Minha Conta</button>
                            <button onClick={()=>logout()} className="  px-4 py-1 rounded hover:opacity-90 font-semibold w-full sm:w-40" style={{backgroundColor:'var(--secondary)', color:'var(--primary)'}}>Sair</button>
                          </div>
                        </div>
                      </div>
                      <div className="w-full flex flex-col gap-3 border-b border-gray-600 py-4 justify-center items-center w-full" style={{display:user?.id?'none':'flex'}}>
                        <img 
                          className={`rounded-full p-2 w-20 h-20`}
                          style={{border:'1px solid var(--secondary)'}} 
                          src="https://militaryhealthinstitute.org/wp-content/uploads/sites/37/2021/08/blank-profile-picture-png.png" 
                        />
                        <div>
                          <span className="text-white text-xl font-semibold">Você já criou sua conta conosco?</span>
                        </div>
                        <div className="flex flex-col sm:flex-row sm:justify-center gap-3 w-100">
                          <div className="flex flex-col gap-1">
                            <button onClick={()=>signup()} className="px-4 py-1 rounded hover:opacity-90 font-semibold w-full" style={{backgroundColor:'var(--secondary)', color:'var(--primary)'}}>Cadastre-se</button>
                          </div>
                          <div className="flex flex-col gap-1">
                            <button onClick={()=>login()} className="px-4 py-1 rounded hover:opacity-90 font-semibold w-full" style={{backgroundColor:'var(--secondary)', color:'var(--primary)'}}>Login</button>
                          </div>
                        </div>
                        
                      </div>
                    </div>
                  </div>
                  <nav aria-label="Sidebar" style={{marginTop: '38px'}} >
                    <div className="px-4 space-y-1 flex flex-col">
                      {navigation.map((item) => (
                        <a
                          key={item._id}
                          href={item.redirect_to ? item.redirect_to : item.page_path}
                          className={`group flex items-center px-2 py-2 text-xl font-medium rounded-md bg-secondary-custom hover:no-underline order-${item.order}`}
                        >
                          {/* <item.icon
                            className={classNames(
                              item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                              'mr-4 h-6 w-6'
                            )}
                            aria-hidden="true"
                          /> */}
                          {item.menu_name}
                        </a>
                      ))}
                    </div>
                  </nav>

                  <div className='max-w-lg w-full px-4' style={{position:'fixed', bottom:'15px'}}>
                    <div className=" w-full border-t  border-gray-700 py-4">
                      <span className="text-white text-xl" >Siga-nos</span>
                      <div className="flex gap-3 mt-3" style={{color:'#bbb'}}>
                        <a href={"https://www.instagram.com/"+dataJSON.customer.sm_instagram.replace('@', '')} style={{display:dataJSON.customer.sm_instagram?'':'none'}}>
                          <img alt="" className="w-8 h-8 cursor-pointer" src={iconInstagran} />
                        </a>
                        <a href={"https://www.facebook.com"+dataJSON.customer.sm_facebook} style={{display:dataJSON.customer.sm_facebook?'':'none'}}>
                          <img alt="" className="w-8 h-8 cursor-pointer" src={iconFacebook} />
                        </a>
                        <a href={"https://www.youtube.com/JebalaPlay"+dataJSON.customer.sm_youtube} style={{display:dataJSON.customer.sm_youtube?'':'none'}}>
                          <img alt="" className="w-8 h-8 cursor-pointer" src={iconYoutube} />
                        </a>
                        <a href={"https://web.whatsapp.com/send?phone="+dataJSON.customer.sm_whatsapp} style={{display:dataJSON.customer.sm_whatsapp?'':'none'}}>
                          <img alt="" className="w-8 h-8 cursor-pointer" src={iconWhatsApp} />
                        </a>
                        <a href={"https://www.tiktok.com/"+dataJSON.customer.sm_tiktok} style={{display:dataJSON.customer.sm_tiktok?'':'none'}}>
                          <img alt="" className="w-8 h-8 cursor-pointer" src={iconTikTok} />
                        </a>
                        <a href={"mailto:"+dataJSON.customer.sm_email} style={{display:dataJSON.customer.sm_email?'':'none'}}>
                          <img alt="" className="w-8 h-8 cursor-pointer" src={iconMail} />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="flex-shrink-0 flex border-t border-gray-200 p-4" style={{display:user.id?'flex':'none'}}>
                  <a href="/my-account" className="flex-shrink-0 group block">
                    <div className="flex items-center">
                      <div>
                        <img
                          className="inline-block h-10 w-10 rounded-full"
                          src="https://dermatoportal.com.br/wp-content/uploads/2020/02/placeholder-profile-male-1.jpg"
                          alt=""
                        />
                      </div>
                      <div className="ml-3">
                        <p className="text-base font-medium text-gray-700 group-hover:text-gray-900">{user.first_name}</p>
                        <p className="text-sm font-medium text-gray-500 group-hover:text-gray-700">Minha Conta</p>
                      </div>
                    </div>
                  </a>
                </div> */}
              </div>
            </Transition.Child>
            <div className="flex-shrink-0 w-14" aria-hidden="true">
              {/* Force sidebar to shrink to fit close icon */}
            </div>
          </Dialog>
        </Transition.Root>

      </div>
    </>
  )
}

export default MenuSidebar;