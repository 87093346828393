import * as React from "react"
import {Helmet} from "react-helmet";
import { useEffect, useState} from "react"
import dataJSON from '../data/data'
import ls from 'local-storage'

const isBrowser = typeof window !== "undefined"


const Init = (props) => {

  

    const [loadComplete, setLoadComplete] = useState(false) 

    const initConfig = ()=>{

        if(!isBrowser) return

        setTimeout(()=>{
          window.dataJSON = dataJSON
        })


        let params = new URLSearchParams(window.location.search);

        if(params.has('sandbox') && params.get('sandbox') === 'true'){
          ls.set('sandbox', 'true')
        }
        
        if(ls.get('sandbox') && ls.get('sandbox')==='true'){
          window.baseUrlRequest = 'https://'+dataJSON.angulare_settings.api_url_sandbox
        }
        else{
          window.baseUrlRequest = 'https://'+dataJSON.angulare_settings.api_url_production
        }

        //window.baseUrlRequest = 'https://arenabtg.dev.angular-ecommerce.com'


          

        
        // console.log(dataJSON.customer_app.rows[0].app_url)
        // window.baseUrlRequest = dataJSON.customer_app.rows[0].app_url
        //window.baseUrlRequest = dataJSON.domain

        //document.documentElement.style.setProperty('--primary', dataJSON.primaryColor);
        //document.documentElement.style.setProperty('--bs-primary', dataJSON.primaryColor);


        setLoadComplete(true)

        window.addEventListener('settingsReady', ()=>{
                    
            setLoadComplete(true)
            props.setPageReady(true)

            let salesPage = null
            if(dataJSON.app_pages.filter(item=>item.page_type=='sales').length){
              salesPage = dataJSON.app_pages.filter(item=>item.page_type=='sales')[0].page_path
            }


            window.AngularEcommerce.salesPage = salesPage;

            const defaultConfig = {
                packagePageUrl: salesPage || '/pacotes',
            }   

            window.AngularEcommerce.web.config = {...window.AngularEcommerce.web.config, ...defaultConfig}


            loginComponentActive()
            checkoutComponentActive()
            signComponentActive()
            forgotPasswordComponentActive()


            

            
        })

    }


    const loginComponentActive = ()=>{
            var loginModal = undefined

            loginModal = document.createElement('ae-login-modal')
            loginModal.addEventListener('close', () => { loginModal.show = false })
            loginModal.addEventListener('loginSuccess', () => { 
              loginModal.show = false; 
              const event = new CustomEvent('userCurrentReady', {
                  detail: 'ok'
              });              
      
             window.dispatchEvent(event);
            })
            loginModal.addEventListener('showSignup', () => { 
              loginModal.show = false;
              document.querySelector('ae-signup-modal').show = true
            })
            loginModal.addEventListener('showForgetPassword', () => { 
              loginModal.show = false;
              document.querySelector('ae-forgot-password-modal').show = true
            })


            

            document.body.appendChild(loginModal)
    }

    const checkoutComponentActive = ()=>{
      var checkoutModal = undefined

      let interval = setInterval(()=>{

        if(document.querySelector('ae-login-modal')){

          clearInterval(interval)

          checkoutModal = document.createElement('ae-checkout-modal')
          checkoutModal.addEventListener('close', () => { checkoutModal.open = false })

          checkoutModal.addEventListener('continueBuying', () => { 
            if(window.AngularEcommerce.salesPage){
                if(window.location.pathname === window.AngularEcommerce.salesPage){
                  checkoutModal.open = false
                }else{
                  window.location.href = window.AngularEcommerce.salesPage
                }
            }else{
              checkoutModal.open = false
            }
          })

          document.body.appendChild(checkoutModal)
        }

      },1000)
    }

    const signComponentActive = ()=>{
      var signupModal = undefined

      let interval = setInterval(()=>{

        if(document.querySelector('ae-login-modal')){

          clearInterval(interval)

          signupModal = document.createElement('ae-signup-modal')
          signupModal.addEventListener('close', () => { signupModal.show = false })
          signupModal.addEventListener('showLogin', () => { 
            signupModal.show = false
            document.querySelector('ae-login-modal').show = true

          })

          document.body.appendChild(signupModal)

          document.querySelector('ae-signup-modal').config = {}

          document.querySelector('ae-signup-modal').config.excludeFields = ['weight', 'height', 'shoe_size', 'tshirt_size', 'social_instagram']
          


        }

      },1000)
    }

    const forgotPasswordComponentActive = ()=>{
      var forgotPasswordModal = undefined

      let interval = setInterval(()=>{

        if(document.querySelector('ae-login-modal')){

          clearInterval(interval)

          forgotPasswordModal = document.createElement('ae-forgot-password-modal')
          forgotPasswordModal.addEventListener('close', () => { forgotPasswordModal.show = false })
          forgotPasswordModal.addEventListener('success', () => { forgotPasswordModal.show = false })
          forgotPasswordModal.addEventListener('showLogin', () => { 
            forgotPasswordModal.show = false
            document.querySelector('ae-login-modal').show = true
          })
          forgotPasswordModal.addEventListener('showSignup', () => { 
            forgotPasswordModal.show = false
            document.querySelector('ae-signup-modal').show = true
          })
          
          document.body.appendChild(forgotPasswordModal)
        }

      },1000)
    }



  useEffect(()=>{
    initConfig()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <>
      {
        (()=>{
          if(!loadComplete){
            return(
              <div style={{
                      backgroundColor:'white', 
                      display:'flex',
                      flexDirection:'column',
                      alignItems:'center',
                      justifyContent:'center',
                      weight:'100vw',
                      height:'100vh',
                      zIndex:100
                    }}>
                <div className="lds-dual-ring"></div>
                Carregando...
              </div>
            )
          }
        })()
      }

      

      {<Helmet>
        <script dangerouslySetInnerHTML={{
                __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var
                f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','GTM-YOURID');`,
            }}
        />
      </Helmet>}


      {<Helmet>
        <script src={`https://static.angulare.app/web/v1.54.416/web-loader.js`}  key="my-script" type="text/javascript" id="script-angulare"></script>
      </Helmet>}

      <Helmet>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/tailwindcss/2.2.19/tailwind.min.css" integrity="sha512-wnea99uKIC3TJF7v4eKk4Y+lMz2Mklv18+r4na2Gn1abDRPPOeef95xTzdwGD9e6zXJBteMIhZ1+68QC5byJZw==" crossorigin="anonymous" referrerpolicy="no-referrer" />
      </Helmet>

      <Helmet>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css" integrity="sha512-KfkfwYDsLkIlwQp6LFnl8zNdLGxu9YAA1QvwINks4PhcElQSvqcyVLLD9aMhXd13uQjoXtEKNosOWaZqXgel0g==" crossorigin="anonymous" referrerpolicy="no-referrer" />
      </Helmet>


      

      
    </>
  )
}

export default Init;
