import React, {useState} from 'react';
import ls from 'local-storage'
import dataJSON from '../../data/data.js'
import iconInstagran from '../../assets/svg/icon-instagram.svg'
import iconTikTok from '../../assets/svg/icon-tiktok.svg'
import iconWhatsApp from '../../assets/svg/icon-whats.svg'
import iconYoutube from '../../assets/svg/icon-youtube.svg'
import iconMail from '../../assets/svg/icon-mail.svg'
import iconFacebook from '../../assets/svg/icon-facebook.svg'
import Moment from 'moment'

//console.log(dataJSON)

function exitSandbox(){
  ls.remove('sandbox')
  window.location.href = '/'
}

// function createMarkup() {
//   return {
//      __html: '<b>asa</b>'    };
// };

const Footer = (props) => {

const [barBottonSandbox, setBarBottonSandbox] = useState(true)

return(
  <>
    <div className='px-5 flex justify-center mt-48' style={{width:'100%', height:'auto', backgroundColor:'var(--primary)'}}>
      <div className='w-full flex flex-col gap-10 lg:gap-0 lg:flex-row justify-between text-white py-20 container'>
        <div className="flex flex-col gap-10">
          <div className="flex">
            <a className='rounded' style={{display:dataJSON.customer_app.app_logo.length?'flex':'none', fontWeight:'200', textDecoration: 'none', color: 'white'}} href="/">
              <img alt="" style={{height:'85px'}} src={'https://budibase.internal.angulare.app'+dataJSON.customer_app.app_logo['0'].url} />
            </a>
            <a className='p-2 px-5 rounded text-2xl capitalize font-bold' style={{display:dataJSON.customer_app.app_logo.length?'none':'flex', backgroundColor:'#121214', fontWeight:'200', textDecoration: 'none', color: 'white'}} href="/">
              {dataJSON.customer_app.tenant}
            </a>
          </div>
          <div className="flex gap-3" style={{color:'#bbb'}}>
            <a href={"https://www.instagram.com/"+dataJSON.customer.sm_instagram.replace('@', '')} style={{display:dataJSON.customer.sm_instagram?'':'none'}}>
              <img alt="" className="w-8 h-8 cursor-pointer" src={iconInstagran} />
            </a>
            <a href={"https://www.facebook.com"+dataJSON.customer.sm_facebook} style={{display:dataJSON.customer.sm_facebook?'':'none'}}>
              <img alt="" className="w-8 h-8 cursor-pointer" src={iconFacebook} />
            </a>
            <a href={"https://www.youtube.com/JebalaPlay"+dataJSON.customer.sm_youtube} style={{display:dataJSON.customer.sm_youtube?'':'none'}}>
              <img alt="" className="w-8 h-8 cursor-pointer" src={iconYoutube} />
            </a>
            <a href={"https://web.whatsapp.com/send?phone="+dataJSON.customer.sm_whatsapp} style={{display:dataJSON.customer.sm_whatsapp?'':'none'}}>
              <img alt="" className="w-8 h-8 cursor-pointer" src={iconWhatsApp} />
            </a>
            <a href={"https://www.tiktok.com/"+dataJSON.customer.sm_tiktok} style={{display:dataJSON.customer.sm_tiktok?'':'none'}}>
              <img alt="" className="w-8 h-8 cursor-pointer" src={iconTikTok} />
            </a>
            <a href={"mailto:"+dataJSON.customer.sm_email} style={{display:dataJSON.customer.sm_email?'':'none'}}>
              <img alt="" className="w-8 h-8 cursor-pointer" src={iconMail} />
            </a>
          </div>
        </div>
        <div>
          <p className="" style={{fontWeight:'500', fontSize:'18px'}}>Navegação</p>
          <div className="flex flex-col" style={{color:'#bbb', gap:'5px'}}>
            {

                dataJSON.app_pages.filter(item=>item.menu_name && (item.menu_show.filter(item2=>item2=='desktop').length || item.menu_show.filter(item2=>item2=='mobile').length)).map((item, index)=>(
                    <a key={index} className={`text-white hover:no-underline hover:text-white hover:opacity-80 order-${item.order}`} href={item.redirect_to?item.redirect_to:item.page_path}>
                      {item.menu_name}
                    </a>
                ))
                
            }
            
          </div>
        </div>
        <div>
          <p className="" style={{fontWeight:'500', fontSize:'18px', display:dataJSON.app_pages.filter(item=>item.menu_name && item.menu_show.filter(item2=>item2=='footer').length).length ? '' : 'none' }}>Mais informações</p>
          <div className="flex flex-col" style={{color:'#bbb', gap:'5px'}}>
            {

                dataJSON.app_pages.filter(item=>item.menu_name !== '' && item.menu_show.filter(item2=>item2=='footer').length && item.menu_show.length===1).map((item, index)=>(
                    <a key={index} className={`text-white hover:no-underline hover:text-white hover:opacity-80 order-${item.order}`} href={item.redirect_to?item.redirect_to:item.page_path}>
                      {item.menu_name}
                    </a>
                ))
                
            }
            
          </div>
        </div>
      </div>
      <div
              dangerouslySetInnerHTML={{ __html: `
                  <link rel="stylesheet" href="${dataJSON.customer_app.app_bootstrap_css.length?'https://budibase.internal.angulare.app'+dataJSON.customer_app.app_bootstrap_css['0'].url:''}">
              ` }}
            />
      </div>
    <p className={`text-center mt-3 ${ls.get('sandbox') && ls.get('sandbox')==='true' && barBottonSandbox ? 'mb-32' :''}`}> © {Moment().format('YYYY')} Todos os direitos reservados. Powered by <a style={{textDecoration:'none', color:'black'}} target="_blank" rel="noreferrer"  href={'https://angulare.app/?utm_source=cws&utm_campaign='+dataJSON.customer.tenant}><b> Angular e-Commerce.</b></a></p>
    
    {
      (()=>{
        if(ls.get('sandbox') && ls.get('sandbox')==='true' && barBottonSandbox){
          return(
            <div  className="w-full h-28 fixed" style={{bottom:0, backgroundColor:'#b91c1cd9'}}>
              <div className="w-full h-full text-white flex flex-col justify-center items-center">
                <div className="text-base md:text-xl flex gap-2 items-center justify-between w-full px-10">
                  <div></div>
                  <div className="flex items-center gap-5">
                    <i className="fa-solid fa-triangle-exclamation" style={{fontSize:'18px'}}></i>
                    <span className="text-center">Você está em ambiente de testes</span>
                  </div>
                  <span aria-hidden="true"  onClick={()=>setBarBottonSandbox(false)} onKeyDown={()=>setBarBottonSandbox(false)} style={{cursor:'pointer'}}><i className="fa-solid fa-xmark hover:text-gray-200" style={{fontSize:'18px'}}></i></span>
                </div>
                <button className="bg-red-800 px-3 py-2 mt-2 rounded hover:bg-red-900" onClick={()=>exitSandbox()}>
                  Sair do ambiente de testes
                </button>
              </div>
            </div>
          )
        }
      })()
    }
    
  </>
)};

export default Footer;
