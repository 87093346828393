import React from 'react';
import { useEffect, useState } from 'react';
import dataJSON from '../../data/data.js'
// import PopoverUser from '../../components/popover-user'

//console.log(dataJSON)

//import { Link } from 'gatsby'

const isBrowser = typeof window !== "undefined"

const Header = (props) =>
{

  const [user, setUser] = useState(null)


  useEffect(()=>{

  }, [])

  function userCurrentReady(){
    if (!isBrowser) return
    window.addEventListener('userCurrentReady', ()=>{
      setTimeout(()=>{
        setUser(window.AngularEcommerce.web.stores.state.auth.user)
      })
    })
  }

  userCurrentReady()

  function login(event){

    //event.preventDefault();

    setTimeout(()=>{
      document.querySelector('ae-login-modal').show = true
    })
  }

  function signup(event){

    //event.preventDefault();

    setTimeout(()=>{
      document.querySelector('ae-signup-modal').show = true
    })
  }

  function checkout(event){

    //event.preventDefault();

    setTimeout(()=>{
      document.querySelector('ae-checkout-modal').open = true
    })
  }

  function logout(){
    if (!isBrowser) return
    window.AngularEcommerce.web.stores.dispatch('auth/logout')
    .then(()=>{
      setTimeout(()=>{
        window.location.href = "/"
      },500)
    })
  }

  function existPageSales(){    
    let salesPage = null
    if(dataJSON.app_pages.filter(item=>item.page_type=='sales').length){
      salesPage = dataJSON.app_pages.filter(item=>item.page_type=='sales')[0].page_path
    }

    return salesPage;
  }

  // function myaccount(){
  //   if (!isBrowser) return
  //   window.location.href = "/my-account"

  // }

  // function signup(event){

  //   //event.preventDefault();

  //   setTimeout(()=>{
  //     document.querySelector('ae-signup-modal').show = true
  //   })
  // }

  return(
  <div className='px-1 md:px-5 flex justify-center' style={{width:'100%', height:'90px', backgroundColor:'var(--primary)'}}>
    <div className="container md:w-auto flex items-center justify-between h-100 text-white" >
      <div
         className="flex items-center w-full md:w-auto justify-between md:justify-start hidden md:flex"
         style={{gap:'20px', cursor:'pointer'}}>
        <i
          aria-hidden="true"
          className="fa-solid fa-bars"
          onKeyDown={()=>props.menuChangeStatus('menu')}
          onClick={()=>props.menuChangeStatus('menu')}
          style={{fontSize:'30px'}}>

        </i>
        <a className='md:p-2 md:px-5 rounded ' style={{display:dataJSON.customer_app.app_logo.length?'flex':'none', fontWeight:'200', textDecoration: 'none', color: 'white'}} href="/">
          <img style={{height: '85px'}}  alt="" src={'https://budibase.internal.angulare.app'+dataJSON.customer_app.app_logo['0'].url} />
        </a>
        <a className='p-2 px-5 rounded text-2xl capitalize font-bold ' style={{display:dataJSON.customer_app.app_logo.length?'none':'flex', backgroundColor:'#121214', fontWeight:'200', textDecoration: 'none', color: 'white'}} href="/">
          {dataJSON.customer_app.tenant}
        </a>
      </div>

      <div
         className="flex items-center w-full md:w-auto justify-between md:justify-start md:hidden"
         style={{gap:'20px', cursor:'pointer'}}>
        
        <a className='md:p-2 md:px-5 rounded ' style={{display:dataJSON.customer_app.app_logo.length?'flex':'none', fontWeight:'200', textDecoration: 'none', color: 'white'}} href="/">
          <img style={{height: '70px'}}  alt="" src={'https://budibase.internal.angulare.app'+dataJSON.customer_app.app_logo['0'].url} />
        </a>
        <a className='p-2 px-5 rounded text-2xl capitalize font-bold ' style={{display:dataJSON.customer_app.app_logo.length?'none':'flex', backgroundColor:'#121214', fontWeight:'200', textDecoration: 'none', color: 'white'}} href="/">
          {dataJSON.customer_app.tenant}
        </a>
        <i
          aria-hidden="true"
          className="fa-solid fa-bars"
          onKeyDown={()=>props.menuChangeStatus('menu')}
          onClick={()=>props.menuChangeStatus('menu')}
          style={{fontSize:'30px'}}>

        </i>
      </div>


      <div className="flex items-center gap-5 hidden md:flex">
        <div className='flex' style={{gap:'6px'}}>
            {
              (()=>{
              if(existPageSales()){
                return(
                  <a className="px-4 py-2 rounded bg-gray-200 text-black hover:no-underline hover:text-black hover:bg-gray-300 font-semibold" href="/pacotes">Pacotes & Planos</a>
                )
              }
              })()
            }
          
          <a style={{display:user?'':'none'}} className="px-4 py-2 rounded bg-gray-200 text-black hover:no-underline hover:text-black hover:bg-gray-300 font-semibold" href="/my-account">Minha Conta</a>
        </div>
        <div className="flex items-center gap-5">
          <div className="flex" style={{gap:'10px'}}>
          {
            (()=>{
              if(!user){
                return(
                  <>
                    {
                      (()=>{
                        if(existPageSales()){
                          return(
                            <i onClick={()=>login()}  className="fa-solid fa-circle-user text-white " aria-hidden="true" style={{fontSize:'32px', cursor:'pointer'}}></i>
                          )
                          
                        }else{
                          return(
                            <div className="flex gap-5">
                              <button onClick={()=>login()} className="px-4 py-2 rounded bg-gray-200 text-black hover:no-underline hover:text-black hover:bg-gray-300 font-semibold" >Login</button>
                              <button onClick={()=>signup()} className="px-4 py-2 rounded bg-gray-200 text-black hover:no-underline hover:text-black hover:bg-gray-300 font-semibold" >Cadastre-se</button>
                            </div>
                          )
                        }
                      })()
                    }
                    
                  </>
                )
              }else{
                return(
                  <>
                    <i onClick={()=>logout()}  className="fa-solid  fa-arrow-right-from-bracket text-white" aria-hidden="true" style={{fontSize:'25px', cursor:'pointer'}}></i>
                  </>
                )

              }
            })()
          } 
          </div>
          {
            (()=>{
              if(existPageSales()){
                return (
                  <i onClick={()=>checkout()}  className="fa-solid fa-cart-shopping " aria-hidden="true" style={{fontSize:'25px', cursor:'pointer', color:'var(--secondary)'}}></i>
                )
              }
            })()
          }
          
        </div>
      </div>
    </div>

  </div>
)};

export default Header;
