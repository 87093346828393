import React, {useState, useEffect} from 'react';
import Footer from './footer';
import Header from './header';
import MenuSidebar from '../../components/menu';

const Layout = ({ children, data }) => {

  const [sidebarOpen, setSidebarOpen] = useState(false)

  useEffect(()=>{

  },[])

  const menuChangeStatus = (data)=>{
    setSidebarOpen(!sidebarOpen)
  }

  return (
    <>
      <MenuSidebar menuChangeStatus={menuChangeStatus} sidebarOpen={sidebarOpen}/>
      <Header menuChangeStatus={menuChangeStatus} data={data} />
      <main className="">{children}</main>
      <Footer data={data} />
    </>
  );
};

export default Layout;
